:root {
  font-weight: 400;
  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

* {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1;
  letter-spacing: 0.05em;
  user-select: none;
}

a {
  color: inherit;
  &:hover {
    color: inherit
  }
}

.gif-card-container {
  margin: 5px;

  @media (min-width: 400px) {
    margin: 10px;
  }

  @media (min-width: 550px) {
    margin: 15px;
  }

  @media (min-width: 1050px) {
    margin: 20px;
  }

  &__card {
    width: 170px;
    height: auto;

    @media (min-width: 450px) {
      width: 190px;
      height: auto;
    }

    @media (min-width: 550px) {
      width: 210px;
      height: auto;
    }

    @media (min-width: 1050px) {
      width: 230px;
      height: auto;
    }

    @media (min-width: 1450px) {
      width: 250px;
      height: auto;
    }
  }
}

.ant-card-body {
  padding: 13px !important;
}

.ant-card-meta-title {
  padding-bottom: 5px;
  font-size: 1.15rem !important;
}